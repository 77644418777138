export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Inicio',
        to: '/Inicio',
        icon: 'cil-home',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['secciones']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Usuarios',
        to: '/user',
        icon: 'cilPeople'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Vídeos',
        to: '/video',
        icon: 'cilVideo'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Consejos',
        to: '/consejo',
        icon: 'cilLightbulb'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Cuestionarios',
        to: '/cuestionario',
        icon: 'cilClipboard'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Premios',
        to: '/premio',
        icon: 'cilBadge'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Categorías',
        to: '/categoria',
        icon: 'cilApps'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Estadísticas',
        to: '/estadisticas',
        icon: 'cilBarChart'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Sesión']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Cerrar sesión',
        to: '/pages/login',
        icon: 'cilXCircle',
      },
    ]
  }
]