<template>
  <CHeader
    fixed
    with-subheader
    light
  >
    <CSubheader class="px-3">
      <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
      />
      <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
      />
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
export default {
  name: "TheHeader",
  components: {},
};
</script>
