<template>
  <CFooter :fixed="false">
    <div>
      Deja de Fumar
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} Bisite.</span>
    </div>
    <div class="mfs-auto">
      <span
        class="mr-1"
        target="_blank"
      >Salamanca</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
